import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/users", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/user/" + id).then(res => res.data);
    },
    create(user) {
        return Api().post("/user", user).then(res => res.data);
    },
    update(user) {
        return Api().put("/user/" + user.id, user).then(res => res.data);
    },
    delete(user) {
        return Api().delete("/user/" + user.id).then(() => user);
    },
    exportList() {
        Api().get("/user/export/list", {responseType: 'blob'}).then(res => downloadData(res));
    },
    formatRes(e) {
        return e;
    }

}